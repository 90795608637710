
.vg-interventions-table{
	height: 90%;
	overflow-y: auto;
	.vg-interventions-cards{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		border-bottom: 1px solid whitesmoke;
		.intervention-card{
			width: 100%;
			border: 1px solid #ddd;
			border-radius: 8px;
			padding: 10px;
			background-color: #fff;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
			.card-header {
				font-size: 16px;
				margin-bottom: 10px;
			}
			.card-body{
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 10px;
			}
			.card-row{
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 5px;
				.item{
					width: 50%;
					display: block;
					.item-header{
						display: inline-flex;
						align-items: center;
						gap:10px;
						font-weight: 600;
					}
					.item-value{
						padding-left:10px;
					}
				}
			}
		}
	}
}
