
$backgroundColorCategorieRow: #ebebeb;
$backgroundColorContratRow: #f5f7f7;
$hover-accent-color: #3999FF;
.vg-synthese-maintenance-preventive-table{
	border: 1px solid #BDC3C7;
	font-size: 12px;
	height: 100%;
	.header{
		display: grid;
		grid-template-columns: minmax(250px, 1fr) 4fr;
		height: 40px;
		background-color: #f5f7f7;
		color: rgba(0, 0, 0, 0.54);
		font-weight: 600;
		font-size: 12px;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
		border-bottom: 1px solid #BDC3C7;
	}
	.body{
		height: calc(100% - 40px);
		overflow-y: auto;
		.table-row{
			display: grid;
			grid-template-columns: minmax(250px, 1fr) 4fr;
			justify-self: stretch;
			align-items: stretch;
			border-bottom: 1px solid #d9dcde;
		}
		.table-row:hover{
			background-color: rgba(0, 0, 0, 0.07);
		}
		.section-row{
			background-color: $backgroundColorCategorieRow;//#E8EAEE;
		}
		.section-container{
			background-color: rgba(0, 0, 0, 0.04);
		}
	}
	.main-cell{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap:5px;
		background-color: rgba(0, 0, 0, 0.02);
		border-right: 1px solid #d9dcde;
		padding: 5px 10px;
		.title{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap:10px;
			font-weight: 600;
			font-size: 14px;
		}
		.subtitle{
			padding-left: 30px;
		}
		.title-inside-container{
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			gap:5px;
			padding-left: 10px;
			.contrat-icon{
				width: 20px;
				height: 20px;
			}
		}
		.contrat-name:hover{
			cursor: pointer;
		}
		.collapser{
			border: 1px solid transparent;
			border-radius: 50%;
		}
		.collapser:hover{
			cursor: pointer;
			border: 1px solid #BDC3C7;
		}
		.icon-categorie{
			width: 20px;
			height: 20px;
		}
	}
	.section-container-hidden{
		visibility: hidden;
    	height: 0px;
	}
	.month-cells{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
		background-color: rgba(0, 0, 0, 0.04);
	}
	.month-cell{
		border-right: 1px solid #d9dcde;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2px;
		.month-cell-badge{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 10px;
			white-space: nowrap;
			padding: 6px 4px 4px 4px;
			border-radius: 4px;
		}
		.month-cell-badge:hover{
			
		}
		.badge-grey{
			background-color: #d2d5dd;
		}
		.badge-red{
			background-color: red;
		}
		.badge-orange{
			background-color: orange;
		}
		.badge-green{
			background-color: #50B659;
		}
		.badge-blue{
			background-color: #3999FF;
		}
	}
	.month-cell-clickable:hover{
		cursor: pointer;
		border: 1px solid $hover-accent-color;
	}
}
