

$main-container-height: calc(var(--vh, 1vh) * 100);

.badge-actives-filters{
    font-size:12px;
    margin-left:10px;
    font-weight:normal;
    color: rgb(108, 58, 158);
    padding: 2px 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    .vg-button{
        color: black;
        border-color: rgb(108, 58, 158);
        height:28px;
    }
}
.badge-filter-recherche-globale{
    font-size:12px;
    margin-left:20px;
    font-weight:normal;
    color: red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:5px;
}

@media only screen and (min-width: 1200px){

    $menu-height: 60px;
    $bandeau-fin-demo: 43px;
    $vg-app-layout-height: calc(#{$main-container-height} - #{$menu-height});
    $vg-app-layout-margin-sides: 20px;
    $header-height: 130px;
    $header-top-height: 80px;
    $body-height: calc(#{$vg-app-layout-height} - #{$header-height});
    $body-padding-top: 10px;
    $body-padding-bottom: 10px;
    $body-primary-max-height: calc(#{$body-height} - #{$body-padding-top} - #{$body-padding-bottom});
    $footer-height-sm: 30px;
    $footer-height-md: 50px;
    $footer-height-lg: 100px;
    $key-figures-panel-width: 300px;
    $key-figures-panel-reduced-width: 60px;

    .vg-fin-demo + .vg-menu-navbar{
        /*$vg-app-layout-height: calc(#{$main-container-height} - #{$menu-height} - #{$bandeau-fin-demo});*/
    }
    .mobile{
        display: none !important;
    }
    .reduced{
        width: 50%;
        margin: auto;
    }

    #app-main-container{
        height: $main-container-height;
        #vg-app-layout{
            height: $vg-app-layout-height;
            /*border: 1px solid blue;*/
            display: flex;
            /*flex-direction: column;*/
            justify-content: space-between;
            align-items: center;
            #wrapper-content{
                height: inherit;
                /*width: calc(100% - #{$key-figures-panel-width});*/
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                /*border: 1px solid green;*/
            }
            /*.default-wrapper-content{
                width: ;
            }*/
            .wide-wrapper-content{
                width: calc(100% - #{$key-figures-panel-reduced-width}) !important;
            }
            .reduced-wrapper-content{
                width: calc(100% - #{$key-figures-panel-width}) !important;
            }
            #wrapper-key-figures-panel{
                height: inherit;
                /*width: $key-figures-panel-width;*/
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                /*border: 1px solid red;*/
            }
            .key-figures-panel-opened{
                width: $key-figures-panel-width !important;
            }
            .key-figures-panel-closed{
                width: $key-figures-panel-reduced-width !important;
            }
            #wrapper-header{
                width: 100%;
                height: $header-height;
                order: 1;
                /*border: 1px solid blue;*/
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                padding: 0 $vg-app-layout-margin-sides;
                #wrapper-header-top{
                    height: $header-top-height;
                    width: 100%;
                    display: flex;
                    justify-content: stretch;
                    align-items: center;
                    flex-wrap: nowrap;
                    border-bottom: 1px solid #AFB6BF;
                    .header-top-left{
                        flex-grow: 1;
                        height: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap:10px;
                        font-size: 24px;
                        font-weight: 400;
                        max-width: 60vw;
                        img{
                            height: 30px;
                            width: 30px;
                        }
                        .header-top-left-title{
                            white-space: nowrap;
                        }
                    }
                    .header-top-left-complement{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;
                        height: inherit;
                        overflow-x: auto;
                    }
                    .header-top-right{
                        flex-grow: 1;
                        height: 100%;
                        .desktop{
                            height: 100%;
                            display: flex !important;
                            justify-content: flex-end;
                            align-items: center;
                            gap:10px;
                            .container-header-top-right{
                                
                            }
                            .container-header-top-search{
                                min-width: 150px;
                            }
                            .container-header-top-create-button{
                                min-width: 200px;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                gap: 10px;
                                *{
                                    width:100%;
                                }
                                button{
                                    flex: 1 !important;
                                    max-width: 200px;
                                    min-width: 150px;
                                }
                            }
                            .container-header-top-action-button{
                                min-width: 100px;
                            }
                        }
                    }
                }
                #wrapper-header-bottom{
                    height: calc(#{$header-height} - #{$header-top-height});
                    width: 100%;
                    display: flex;
                    justify-content: stretch;
                    align-items: center;
                    gap:10px;
                    .header-bottom-left{
                        width: 65%;
                        height: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap:10px;
                        .header-bottom-left-multiple-actions{
                            width: 100%;
                            height: 100%;
                            display: inline-flex;
                            padding: 1rem;
                            &.active{
                                //border-radius: 8px;
                                box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                                background: #f8f9fa;
                            }
                            .__count {
                                color: #6c757d;
                                font-size: 0.9rem;
                                margin-bottom: 0.5rem;
                                flex-grow: 1;
                                
                            }
                            .__buttons {
                                width:100%;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 0.5rem;
                                flex-grow: 2;
                            }
                        }
                    }
                    .header-bottom-right{
                        width: 35%;
                        height: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap:10px;
                    }
                }
                #menu-more{
                    background-color: white;
                    color: grey;
                    border-left: 1px solid whitesmoke;
                    height: $vg-app-layout-height;
                    overflow-y: auto;
                    padding: 20px;
                    position: absolute;
                    right: 0px;
                    width: 80%;
                    z-index: 4;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap:10px;
                }
            }
            #wrapper-body{
                height: $body-height;
                max-height: $body-height;
                width: 100%;
                /*border: 1px solid green;*/
                order: 2;
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: flex-start;
                gap: 10px;
                padding: 0 $vg-app-layout-margin-sides;
                #wrapper-body-primary{
                    width:100%;
                    height: 100%;
                    max-height: $body-primary-max-height;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: stretch;
                    align-items: flex-start;
                    >div{
                        width: 100%;
                    }
                }
                #wrapper-body-secondary + #wrapper-body-primary{
                    width: 65%;
                }
                #wrapper-body-secondary{
                    width: 35%;
                    height: 100%;
                    max-height: $body-primary-max-height;
                    overflow-y: auto;
                    .wrapper-body-secondary-sub-container{
                        width: 100%;
                        height: $body-primary-max-height;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
                &.full-primary{
                    #wrapper-body-primary{
                        width: 100% !important;
                    }
                    #wrapper-body-secondary{
                        width: 0%;
                        display: none;
                    }
                }
                &.zoom{
                    gap: 20px;
                    #wrapper-body-primary{
                        width: 35% !important;
                        padding: 20px;
                        border: 1px solid lightgray;
                        >div{
                            width: 100%;
                        }
                    }
                    #wrapper-body-secondary{
                        width: 65% !important;
                    }
                }
            }
            #wrapper-footer{
                height: $footer-height-md;
                width: 100%;
                bottom: 0px;
                background-color: #f2f2f2;
                border-top: 1px solid #ccc;
                display: flex;
                justify-content: space-around;
                align-items: center;
                gap: 10px;
                order: 3;
            }
            .wrapper-footer-height-sm{
                height: $footer-height-sm !important;
            }
            .wrapper-footer-height-md{
                height: $footer-height-md !important;
            }
            .wrapper-footer-height-lg{
                height: $footer-height-lg !important;
            }
            .wrapper-footer-height-sm + #wrapper-body{
                height: calc(#{$body-height} - #{$footer-height-sm});
                max-height: calc(#{$body-height} - #{$footer-height-sm});
            }
            .wrapper-footer-height-md + #wrapper-body{
                height: calc(#{$body-height} - #{$footer-height-md});
                max-height: calc(#{$body-height} - #{$footer-height-md});
            }
            .wrapper-footer-height-lg + #wrapper-body{
                height: calc(#{$body-height} - #{$footer-height-lg});
                max-height: calc(#{$body-height} - #{$footer-height-lg});
            }

            #wrapper-body-overlay{
                position: fixed;
                top: $menu-height;
                left: 0;
                height: $vg-app-layout-height;
                width: 100%;
                z-index: 100;
                background-color: white;
            }
        }
        .vg-app-layout-demo{
            height: calc(#{$main-container-height} - #{$menu-height} - #{$bandeau-fin-demo}) !important;
        }
    }
}
