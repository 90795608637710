
.stats-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    gap: 20px;
}

.stat-item {
    text-align: center;
    flex: 1;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    transition: transform 0.2s ease-in-out;
}

.stat-item:hover {
    transform: translateY(-5px);
}

.stat-value {
    font-size: 24px;
    color: #3b82f6;
    font-weight: bold;
}

.stat-label {
    font-size: 14px;
    color: #6b7280;
    margin-top: 8px;
}

.tabs-and-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.etat-equipements-inline {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    justify-content: center;
}
